import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { HelmetSEO } from "../../helpers/HelmetSEO";

const NotFoundImg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fnot-found%2Fnotfound.png?alt=media&token=f2ebb064-ca2d-4c05-aa89-9dae0e7f5200";

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  min-height: 75vh;
  @media only screen and (max-width: 600px) {
    min-height: 55vh;
  }
`;

const Title = styled.h1`
  color: #00ac90;
  font-size: 60px;
  margin-bottom: 0px;
  @media only screen and (max-width: 600px) {
    font-size: 40px;
  }
`;

const StyledImg = styled.img`
  width: 90%;
  margin: 40px 0 0 5%;
`;

export const NotFound = () => {
  return (
    <>
      <HelmetSEO
        title="Pagina no encontrada"
        description="Lo sentimos, la página que buscas no está disponible."
        image={NotFoundImg}
        url={window.location.href}
      />
      <StyledContainer>
        <Row>
          <Col xs={12} lg={5} className="d-flex align-items-center">
            <Title>Página no encontrada...</Title>
          </Col>
          <Col xs={12} lg={7}>
            <StyledImg src={NotFoundImg} alt="Página no encontrada" />
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
};
