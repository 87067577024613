import './App.css';
import { AppRouter } from './routes/AppRouter';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AOS from 'aos';
import WhatsAppButton from '../src/components/general/WhatsAppButton';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import MetricoolScript from './helpers/MetricoolScript';
// import ReactGA from 'react-ga';
// import ReactGA from 'react-ga4';
// const TRACKING_ID = "G-3MPCQ3JGQC";  OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

function App() {
	useEffect(() => {
		AOS.init({ duration: 2000 });
		AOS.refresh();
	}, [])
	const stripeKey = loadStripe(
		'pk_test_51HB5FdFXCgaXtV7e0RJkxbKJLqZhdyemy1bW5iB94IJlG5FfRt1OzAN4nn888cBMSUgODxwyJytJjj2ZvA88tyKk00G4UUAcLv'
	);
	// ReactGA.initialize(TRACKING_ID)
	return (
		<Elements stripe={stripeKey}>
			<MetricoolScript/>
			<AppRouter />
			<WhatsAppButton/>
		</Elements>
	);
}

export default App;