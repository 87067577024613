import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { UilPlus, UilCheck, UilEye } from "@iconscout/react-unicons";
import { useSelector } from "react-redux";
import { PrivRoutes } from '../../routes/routes';

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CardContainer = styled.div`
  transition: all 0.5s ease-out;
  margin: auto;
  position: relative;
  background: #ffffff;
  width: 100%;
  padding: 10px 65px 0px 0;
  margin-bottom: 5px;
  border-bottom: 2px solid #e0ecec;
  display: flex;
  justify-content: space-between;
  .row .col-3 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  #add,
  #added {
    padding: 7px 2px 7px 16px;
    border-left: 2px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    font-weight: 800;
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(0, -50%);
    @media only screen and (max-width: 768px) {
      padding: 7px 2px 7px 8px;
    }
    p {
      margin-bottom: 0px;
    }
  }
  #add-btn,
  #added-btn {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  #precios {
    @media only screen and (min-width: 768px) {
      display: flex;
    }
  }
  #sucursal {
    color: #3b3b3b;
    padding-right: 0px;
    p {
      width: auto;
      margin-right: 10px;
    }
  }
  #detail a {
    color: ${(props) => props.mainColor};
    text-decoration: underline;
  }
  #online {
    font-weight: 800;
    margin-right: 16px;
    padding-right: 0px;
    p {
      width: auto;
      @media only screen and (max-width: 768px) {
        margin-bottom: 3px;
      }
    }
  }
`;

const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-weight: 800;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: -0.03em;
  text-decoration-line: underline;
  color: #3b3b3b;
  // color: ${(props) => props.mainColor};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const StudySearch = (props) => {
  const location = useLocation();
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const { pathname } = useLocation();
  const { items } = useSelector((state) => state.shoppingCart);

  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };
  const [existsItem, setExistsItem] = useState(
    items.some((e) => e.id === props.data.id)
  );
  const mainColor = PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90";
  useEffect(() => {
    props.studies 
      ?
        props.studies?.some((e) => e.id === props.data.id)
        ? setExistsItem(true)
        : setExistsItem(false)
      : 
        items.some((e) => e.id === props.data.id)
          ? setExistsItem(true)
          : setExistsItem(false)
  }, [items, props.studies]);

  return (
    <CardContainer mainColor={mainColor}>
      <div>
        <Title
          mainColor={mainColor}
          onClick={() => {
            if (pathname.includes("paquete")) {
              props.addItem({ ...props.data });
              setExistsItem(true);
            } else {
              window.location.href = `/estudio/${props.data.link}`;
            }
          }}
        >
          {props.data.title}
        </Title>
        {props.idClienteLabopat && props.idClienteLabopat !== 0 ? (
          <>
            <p color={mainColor === "#4F90BA" ? "#000000" : "#F9AD62"}>
              Precio de convenio: {currency(props.data.price)} MXN
              {pathname.includes("paquete") && (
              
                <a id="detail"
                  href={`estudio/${props.data.link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <UilEye color="#4D99E5" size={17} style={{ paddingLeft: "2px", marginTop: "-4px" }} />
                  Ver detalle
                </a>
              
            )}
            </p>
          </>
        ) : (
          <div id="precios">
            <div id="online">
              {props.data.categoria !== 10 && props.data.categoria !== 8 && (
                <p style={{ color: mainColor }}>
                  En línea {currency(props.data.price)} MXN
                </p>
              )}
            </div>
            <div id="sucursal">
              <p>En sucursal {currency(props.data.oldPrice)} MXN</p>
            </div>
            {pathname.includes("paquete") && (
              <div id="detail">
                <a
                  href={`estudio/${props.data.link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <UilEye color="#4D99E5" size={17} style={{ paddingLeft: "2px", marginTop: "-4px" }} />
                  Ver detalle
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      {existsItem ? (
        <div xs={4} id="added">
          <div
            id="added-btn"
            style={{ background: mainColor === "#4F90BA" ? "#4F90BA" : "#00AC90" }}
          >
            <UilCheck color="#fff" size={17} style={{ marginTop: "-4px" }} />
          </div>
          <p style={{ color: mainColor === "#4F90BA" ? "#4F90BA" : "#00AC90" }}>Agregado</p>
        </div>
      ) : (props.data.categoria !== 10 &&
          (props.data.categoria !== 8) !== 8 &&
          props.data.ventaOnline !== 0) ||
        isDoctor ? (
        <div
          id="add"
          onClick={() => {
            props.addItem({ ...props.data });
          }}
          style={{ cursor: "pointer" }}
        >
          <div
            id="add-btn"
            style={{ background: mainColor === "#4F90BA" ? "#4F90BA" : "#00AC90" }}
          >
            <UilPlus color="#fff" size={17} style={{ marginTop: "-4px" }} />
          </div>
          <p style={{ color: mainColor === "#4F90BA" ? "#4F90BA" : "#00AC90" }}>Agregar</p>
        </div>
      ) : (
        <></>
      )}
      {/* <Prices>
        
      </Prices> */}
    </CardContainer>
  );
};
