import React, { useEffect } from "react";
import { Banner } from "../components/home/banners/Banner";
import { OurServices } from "../components/content/home/ourServices";
import { App } from "../components/home/app";
import { HorizontalScrollSection } from "../components/home/HorizontalScrollSection";
import { Doubts } from "../components/home/doubts";
import { HelmetSEO } from "../helpers/HelmetSEO";

export const Home = (props) => {
  useEffect(() => {
    // 👇️ scroll to top on page load to avoid scroll to video
    window.scrollTo({ top: -10, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div style={{ background: "#FFFFFF" }}>
      <HelmetSEO
        title="Laboratorio de Alta Especialidad, patología clínica"
        description="Laboratorio de alta especialidad. Servicios de patología clínica, patología quirúrgica y citología,servicios genéticos, secuenciación y biología molecular. Líder diganósticos en México"
        url="https://www.labopat.mx/"
      />
      <Banner
        setShowMenu={(e) => props.setShowMenu(e)}
        setShowSearcher={(e) => props.setShowSearcher(e)}
        setShowCart={props.setShowCart}
      />
      <App />
      <OurServices />
      <HorizontalScrollSection />
      {/* <HowDoesItWork/> */}
      <Doubts />
    </div>
  );
};
