import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { UilTimes } from '@iconscout/react-unicons';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Loader } from '../general/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { updateInfoUser } from '../../actions/user';

const StyledModal = styled(Modal)`
  .modal-dialog {
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #00ac90;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #00ac90;
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

const ContinueButton = styled(Button)`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: #0a7a71;
  }
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const WhiteButton = styled(Button)`
  color: #00ac90;
  width: 241px;
  height: 40px;
  border: none;
  border-radius: 102px;
  margin-top: -10px;
  margin-bottom: 0px;
`;

const DataForm = styled.form`
  width: 90%;
  > .form-floating {
    margin-top: 23px;
  }

  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
  }

  > .form-floating > label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: 'IBM Plex Sans';
  }

  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
  }

  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #00ad92;
  }

  > div input.form-check-input:checked {
    background-color: #00ad92;
  }
  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #00ad90;
  }

  > div input.form-check-input:checked {
    background-color: #00ad90;
  }

  > div .form-check label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  position: static;
  width: 100%;
  height: 58px;
  left: 0px;
  top: 0px;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 20px;
  > input {
    background: #f6f6f6;
  }
`;

const RegularP = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  > a {
    color: #00ad90;
    text-decoration: underline;
  }
`;

let days = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];

let months = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

const years = [];
for (var i = 2025; i >= 1918; i--) {
  years.push(i.toString());
}

const Birthday = styled(Row)`
  > div select {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 12px;
    margin-top: -10px;
  }

  > div label {
    font-size: 12px;
    color: #a6a6a6;
    margin-left: 20px;
    background: #fff;
    margin-bottom: -10px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;

export const ChangeInfoModal = (props) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user?.data?.data);
  const uidProfile = useSelector((state) => state.auth.uid);
  const [imDr, setImDr] = useState(user?.profile?.type == 0 ? false : true);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (props.show) {
      setData(props.state);
    }
  }, [props.show]);

  const createPatientId = async (data) => {
    console.log('paciente', data);
    var form = new FormData();
    form.append('nombre', data.info.nombre);
    form.append('app', data.info.app);
    form.append('apm', data.info.apm);
    form.append('tel', data.info.tel);
    form.append('email', data.info.email);
    form.append('sexo', data.info.gender);
    form.append('fnac', data.info.fnac);
    form.append('edad', 45);
    form.append('uid', data.uid);
    console.log('***********...');
    return await axios.post('https://lpqsystem.com/crea-paciente', form);
  };

  const handleUpdateProfileData = async (formValues) => {
    var idLpqPac = 0;
    var promises = [];
    console.log("FORM DEL HANDLE",formValues);
    
    //console.log('idLpq: ', formValues.idMedicoLabopat);
    
    const birthday = `${formValues.year}-${formValues.month}-${formValues.day}`;
    if (
      formValues.idPacienteLabopat === 0 ||
      formValues.idPacienteLabopat === -1
    ) {
      console.log('------');
      let lpqData = {
        info: {
          nombre: formValues.name,
          app: formValues.fathersLastname,
          apm: formValues.mothersLastname,
          calle: "",
          noext: "",
          noint: "",
          colonia: "",
          ciudad: "",
          estado: "",
          cp: "",
          tel: formValues.phone,
          email: formValues.email,
          fnac: birthday,
          gender: formValues.gender,
        },
        uid: uidProfile,
      };
      const prom2 = createPatientId(lpqData);
      promises.push(prom2);
      
      let a = await Promise.all(promises);
      console.log(a);
      a.forEach((element) => {
        console.log(element.config.url);
        if (
          element.config.url === 'https://lpqsystem.com/crea-paciente'
        ) {
          if (element.data.ok) {
            idLpqPac = element.data.id;
          } else {
            idLpqPac = 0;
          }
        }
      });
    } else {
      console.log('ya tengo id');
      idLpqPac = formValues.idPacienteLabopat;
    }


    var profile = user?.profile ? user?.profile : { type: imDr ? 1 : 0 };
    profile.idPacienteLabopat = idLpqPac;
    profile.type = imDr ? 1 : 0;
    profile.first_time = profile?.first_time ? false : true;
    console.log('profile2: ', profile);

    const newObj = {
      ...formValues,
      birthday,
      profile,
    };

    delete newObj.day;
    delete newObj.month;
    delete newObj.year;
    console.log('f nac', newObj);

    dispatch(updateInfoUser(newObj));

  };

  const onSubmit = async (datal) => {
    setLoading(true);
    var formData = new FormData();
    formData.append('id', datal.id !== '' ? datal.id : data.id);
    formData.append('nombre', data.name);
    formData.append('app', data.fathersLastname);
    formData.append('apm', data.mothersLastname);
    formData.append('email', data.email);
    formData.append('tel', data.phone);
    formData.append('sexo', data.gender);
    formData.append('fnac', `${data.year}-${data.month}-${data.day}`);
    formData.append('uid', uidProfile);
    
    console.log("complete profile** ", props.completeProfile)
    //return
    if(props.completeProfile){
      let response = await axios.post(
        'https://lpqsystem.com/edit-paciente',
        formData
      );
      console.log(response.data);
      if (response.data.ok) {
        await handleUpdateProfileData(data);
        setLoading(false);
        console.log('*******');
        console.log(data);
        props.setStateAfterUpdate(data);
        props.handleClose();
      } else {
        setLoading(false);
      }
    }else{
      let response = await axios.post(
        'https://lpqsystem.com/crea-paciente',
        formData
      );
      console.log("DATA NEWWWW",response.data);

      if (response.data.ok) {
        await handleUpdateProfileData({...data, idPacienteLabopat: parseInt(response.data.id)});
        setLoading(false);
        console.log('*******');
        console.log(data);
        props.setStateAfterUpdate(data);
        props.handleClose();
      } else {
        setLoading(false);
      }
    }
  };

  //console.log(data.day);
  return (
    <>
      <StyledModal show={props.show} onHide={props.handleClose}>
        <div onClick={props.handleClose}>
          <UilTimes color='#fff' style={{ cursor: 'pointer' }} />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>{props.completeProfile ? "Completar datos" : "Editar información"}</Modal.Title><br/>
        </Modal.Header>
        <Modal.Body>
          <DataForm onSubmit={handleSubmit(onSubmit)}>
            {props.completeProfile &&
              <p>Es necesario completar tu información para que creemos tu historial médico</p>
            }
            <Form.Control
              type='hidden'
              {...register('id', {})}
              value={
                props.otherPersonCheck
                  ? data.id
                  : props.user?.profile?.idPacienteLabopat
              }
            />
            <FloatingLabel controlId='floatingName' label='Nombre'>
              <Form.Control
                type='text'
                {...register('name', {
                  required: false,
                })}
                placeholder='Nombre'
                value={data.name}
                onChange={handleChange}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId='floatingSecondName'
              label='Apellido paterno'
            >
              <Form.Control
                type='text'
                {...register('fathersLastname', {
                  required: false,
                })}
                placeholder='Apellido Paterno'
                value={data.fathersLastname}
                onChange={handleChange}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId='floatingLastName'
              label='Apellido materno'
            >
              <Form.Control
                type='text'
                {...register('mothersLastname', {
                  required: false,
                })}
                placeholder='Apellido Materno'
                value={data.mothersLastname}
                onChange={handleChange}
              />
            </FloatingLabel>
            <StyledPhoneInput
              {...register('phone', {
                required: false,
              })}
              international
              defaultCountry='MX'
              placeholder='Teléfono celular'
              id='phoneNumber'
              value={data.phone}
              onChange={(phone) =>
                handleChange({ target: { value: phone, name: 'phone' } })
              }
            />
            <FloatingLabel
              controlId='floatingInput'
              label='Correo electrónico del paciente'
              className='mb-3'
            >
              <Form.Control
                {...register('email', {
                  required: false,
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                    message: 'El correo no cumple con el formato',
                  },
                })}
                type='email'
                placeholder='name@example.com'
                defaultValue={data.email}
                onChange={handleChange}
              />
            </FloatingLabel>

            <RegularP>Fecha de nacimiento</RegularP>
            <Birthday>
              <Col xs={4} style={{ paddingLeft: '0px' }}>
                <Form.Label>Día</Form.Label>
                <Form.Select
                  {...register('day', {
                    required: false,
                    pattern: {
                      value: /[0-9]/i,
                      message: 'Selecciona una fecha',
                    },
                  })}
                  value={data.day}
                  // defaultValue="DD"
                  onChange={handleChange}
                >
                  <option disabled hidden>
                    DD
                  </option>
                  {days.map((day, index) => (
                    <option value={day} key={index}>
                      {day}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={4} style={{ paddingLeft: '0px' }}>
                <Form.Label>Mes</Form.Label>
                <Form.Select
                  {...register('month', {
                    required: false,
                    pattern: {
                      value: /[0-9]/i,
                      message: 'Selecciona una fecha',
                    },
                  })}
                  value={data.month}
                  onChange={handleChange}
                >
                  <option disabled hidden>
                    MM
                  </option>
                  {months.map((month, index) => (
                    <option value={month} key={index}>
                      {month}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={4} style={{ paddingRight: '0px' }}>
                <Form.Label>Año</Form.Label>
                <Form.Select
                  className='form-control'
                  {...register('year', {
                    required: false,
                    pattern: {
                      value: /[0-9]/i,
                      message: 'Selecciona una fecha',
                    },
                  })}
                  value={data.year}
                  onChange={handleChange}
                >
                  <option disabled hidden>
                    AAAA
                  </option>
                  {years.map((year, index) => (
                    <option value={year} key={index}>
                      {year}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Birthday>
            <br />
            <br />
            <RegularP>Sexo</RegularP>
            <Form.Group controlId='genderM'>
              <Form.Check
                {...register('gender', {
                  required: false,
                })}
                value='Masculino'
                id='gender'
                type='radio'
                aria-label='radio 1'
                label='Masculino'
                checked={data.gender === 'Masculino'}
                onChange={handleChange}
              />
            </Form.Group>
            <hr />
            <Form.Group controlId='genderF'>
              <Form.Check
                {...register('gender', {
                  required: false,
                })}
                value='Femenino'
                id='gender'
                type='radio'
                aria-label='radio 3'
                label='Femenino'
                checked={data.gender === 'Femenino'}
                onChange={handleChange}
              />
            </Form.Group>
          </DataForm>
          <ContinueButton onClick={handleSubmit(onSubmit)}>
            {props.completeProfile ? "Completar perfil" : "Guardar cambios"}
          </ContinueButton>
          <WhiteButton variant='light' onClick={props.handleClose}>
            Cancelar
          </WhiteButton>
        </Modal.Body>
      </StyledModal>
      {loading ? <Loader show={loading} /> : null}
    </>
  );
};
