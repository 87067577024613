import React, { useEffect } from "react";

const MetricoolScript = () => {
  useEffect(() => {
    const loadScript = (callback) => {
      const head = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://tracker.metricool.com/resources/be.js";

      script.onreadystatechange = callback;
      script.onload = callback;

      head.appendChild(script);
    };

    loadScript(function () {
      if (window.beTracker) {
        window.beTracker.t({ hash: "cee6c17f9da6408c4d551f1f6e46e5be" });
      }
    });

    return () => {
      const script = document.querySelector(
        'script[src="https://tracker.metricool.com/resources/be.js"]'
      );
      if (script) {
        script.remove();
      }
    };
  }, []);

  return null;
};

export default MetricoolScript;
