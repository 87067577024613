import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { studyInCart } from "../../../../helpers/cart";
import handleAlert from "../../../../helpers/handleAlert";
import { handleAddItemToCart } from "../../../../actions/shoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

const FondoBanner =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2Ffondo_banner_pediatrico.png?alt=media&token=c7971bb2-552d-429b-9553-12331fd30ba4";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 620px;
  /* overflow: hidden; */

  @media (min-width: 601px) and (max-width: 900px) {
    height: 520px;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    height: 460px;
  }
`;

const BgBannerBox = styled.div`
  padding-top: 60px;
  background-image: #E3DAED;
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  height: 100%;

  background-color: #E3DAED;
  @media (min-width: 901px) and (max-width: 1200px) {
    padding-top: 25px;
  }
  @media (min-width: 601px) and (max-width: 900px) {
    padding-top: 0px;
  }
  @media (max-width: 600px) {
    padding-top: 0px;
  }
`;

const Title = styled.h1`
  padding: 2px 0px 2px 0px;
  font-weight: 600;
  color: #000;
  font-size: 55px;
  text-align: center;

  text-decoration-thickness: 2px;
  text-decoration-color: #000;
  text-decoration-skip-ink: auto;
  box-decoration-break: clone;
  span {
    font-size: 55px;
    font-weight: 400;
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: 29px;
    text-align: center;
    padding: 5px 0px 5px 0px;
  }
  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 21px;
    text-align: center;
    padding: 3px 0px 3px 0px;
    margin-bottom: 0;
  }
  @media (max-width: 600px) {
    font-size: 30px;
    text-align: center;
    padding: 4px 0px 4px 10px;
    span {
      font-size: 30px;
      font-weight: 400;
  }
  }
`;

const PagoSucursal = styled.div`
  span {
    color: rgba(84, 86, 90, 1);
    font-size: 25px;
    font-weight: 600;

    @media (max-width: 600px) {
      font-size: 16px;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 15px;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 15px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 25px;
    color: rgba(84, 86, 90, 1);
    @media (max-width: 600px) {
      font-size: 16px;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 20px;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 23px;
    }
  }

  small {
  }
`;

const PagoLinea = styled.button`
  border: none;
  background: #fff;
  text-align: left;
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s;
  padding: 3px;

  &:active {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transform: translateY(0);
  }

  span {
    color: rgba(26, 166, 135, 1);
    font-size: 25px;
    border-bottom: 1px solid rgba(26, 166, 135, 1);
    font-weight: 600;

    @media (max-width: 600px) {
      font-size: 16px;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 15px;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 15px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 34px;
    color: rgba(26, 166, 135, 1);
    border-bottom: 1px solid rgba(26, 166, 135, 1);

    @media (max-width: 600px) {
      font-size: 16px;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 20px;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 23px;
    }
  }

  small {
  }
`;

const Paragraph = styled.p`
  font-weight: 400;
  padding: 2px 0px 2px 0px;
  font-size: 35px;
  color: #000;
  text-align: center;
  line-height: 60px;
  span {
    font-size: 35px;
    color: #1aa687;
  }
  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 17px;
    text-align: center;
    padding: 4px 0px 4px 10px;
    span {
      font-size: 20px;
      line-height: 30px;
      color: #1aa687;
    }
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 17px;
    line-height: 19px;
    text-align: center;
    padding: 5px 0px 5px 0px;
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: 17px;
    line-height: 19px;
    text-align: center;
    padding: 5px 0px 5px 0px;
  }
`;

const BoxPagos = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 100px;
  text-align: left;
  position: relative;
  @media (max-width: 600px) {
    //padding: 10px 0px 10px 0px;
    gap: 40px;
    justify-content: space-evenly;
    padding: 15px;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    justify-content: space-between;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    justify-content: space-between;
  }
`;
const Divider = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5px;
  opacity: 50%;
  border: 1px solid #000;
  height: 70%;
  display: none;
  background-color: #000000;
  @media (max-width: 600px) {
    display: block;
  }
`;

const BtnBox = styled.div`
  position: relative;
  padding: 10px 15px;

  display: flex;
  align-items: center;
  gap: 80px;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 2px;
    padding: 0px 15px;
  }
  @media (min-width: 601px) and (max-width: 900px) {
    flex-direction: column;
    gap: 2px;
    position: relative;
    //padding: 5px 0px 5px 0px;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    flex-direction: column;
    gap: 2px;
    position: relative;
    padding: 5px 0px 5px 0px;
  }

  button {
    background: #f9ad62;
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    padding: 3px 20px;
    border-radius: 15px;
    outline: none;
    border: none;

    @media (max-width: 600px) {
      font-size: 16px;
      padding: 2px 15px;
      position: absolute;
      left: 5%;
      margin-top: 5%;
      display: none;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 19px;
      padding: 5px 15px;
      position: absolute;
      left: 5%;
      display: none;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 18px;
    }
  }
`;

const MarginBox = styled.div`
  background-color: #fff;
  padding: 40px 0px 70px 0px;
  @media (max-width: 600px) {
    padding: 0px 0px 5px 0px;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    padding: 0px 0px 5px 0px;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    padding: 0px 0px 65px 0px;
  }
`;

const CustomImage = styled.img`
  width: 130%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 600px) {
    width: 102%;
    height: 100%;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    position: relative;
    /* top: -140px; */
  }

  @media (min-width: 901px) and (max-width: 1200px) {
  }
`;

const CustomParagrapBorder = styled.p`
  color: rgba(84, 86, 90, 1);
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 11px;
    //border-bottom: 3px solid ${(props) => props.color || "#000"};
  }
  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 13px;
    //border-bottom: 3px solid ${(props) => props.color || "#000"};
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: 16px;

    //border-bottom: 5px solid ${(props) => props.color || "#000"};
  }
`;

const BgCustom = styled.div`
  background-color: transparent;
  padding: 20px 15px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  position: relative;

  @media (max-width: 600px) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 10px 8px;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    padding: 10px 8px;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
  }
`;
const Descuento = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  right: 0;
  border-radius: 10px;
  padding: 5px 20px;
  background-color: #f9ad62;
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  font-weight: 600;
  color: #fff;

  span {
    display: inline-block;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 20px;
    padding: 5px 15px;

    span {
      font-size: 20px;
    }
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 20px;
    line-height: 20px;
    padding: 5px 15px;

    span {
      font-size: 20px;
    }
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: 20px;
    line-height: 20px;

    span {
      font-size: 20px;
    }
  }
`;

const BtnTomaDomicilio = styled.button`
  background: #f9ad62;
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  padding: 3px 20px;
  border-radius: 15px;
  outline: none;
  border: none;

  @media (max-width: 600px) {
    font-size: 16px;
    padding: 2px 15px;
    /* position: absolute; */
    left: 5%;
    margin-top: 5%;
    /* display: none; */
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 19px;
    padding: 5px 15px;
    /* position: absolute; */
    left: 5%;
    /* display: none; */
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: 18px;
  }
`;
const BoxBtnTomaAddStudy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const BannerDomicilio = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 500);
    return () => clearTimeout(timeout);
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const { items } = useSelector((state) => state.shoppingCart);
  const handleClick = () => {
    const phoneNumber = "2212311313";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };
  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  const [local, setLocal] = useState(false);
  const studyId = "panel-pediatrico-para-enfermedades-respiratorias-completo";
  useEffect(() => {
    getInfo();
  }, []);
  const getInfo = async () => {
    try {
      let request = axios.get(
        `https://lpqsystem.com/obtener-estudio/${studyId}/${
          idClienteLabopat ? `${idClienteLabopat}` : "0"
        }`
      );
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.promo_price,
        oldPrice: response.data.normal_price,
      });
      if (response.data.categoria == 6) {
        setLocal(true);
      }
      if (response.data.categoria == 9) {
        setLocal(true);
      }
    } catch (error) {
      console.log(error);
      history.push("/not-found");
    }
  };

  const addStudy = () => {
    const exists = studyInCart(study, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(study, false));
      //history.push("/checkout?sucursal=1");
    }
  };

  const payStudy = () => {
    const exists = studyInCart(study, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(study, false));
      history.push("/checkout");
    }
  };
  return (
    <Wrapper>
      {/* <Box
        sx={{
          height: "90%",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          width: { xs: "15px", md: "25px", lg: "30px" },
          background: "#7AB0EA",
          borderTopRightRadius: "12px",
          borderBottomRightRadius: "12px",
          zIndex: 999,
        }}
      /> */}
      <Grid
        container
        spacing={0}
        sx={{
          height: {
            xs: "auto",
            sm: "auto",
            md: "100%",
            lg: "100%",
            xl: "auto",
          },
        }}
      >
        <Grid
          item
          sm={12}
          md={6}
          lg={4}
          xl={4}
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "250px",
              sm: "260px",
              md: "100%",
              lg: "100%",
              xl: "auto",
            },
            zIndex: {
              xs: 0,
              md: 2,
            },
          }}
        >
          <CustomImage
            src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2Fbannerabu.png?alt=media&token=b1b39aa1-804c-4eb4-8517-17a5292226bf"
            alt="banner-pediatrico"
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          lg={8}
          xl={8}
          sx={{ position: "relative", width: "100%" }}
        >
          <BgBannerBox>
            <MarginBox>
              <BgCustom>
                <Title>
                  ¡Realiza tu prueba{" "}
                  <span>
                    sin
                    <br />
                    salir de casa!
                  </span>
                </Title>
                <br />
                <br />

                <Paragraph>
                  Pregunta por nuestra disponibilidad <br />{" "}
                  <span>y agenda tu cita en un solo click.</span>
                </Paragraph>
              </BgCustom>
              <BoxPagos>
                
              </BoxPagos>

              <BtnBox>
                <CustomParagrapBorder>
                  
                </CustomParagrapBorder>

                <BtnTomaDomicilio onClick={handleClick}>
                  Agenda tu cita
                </BtnTomaDomicilio>
              </BtnBox>
            </MarginBox>
          </BgBannerBox>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default BannerDomicilio;
