// PINK OCTOBER
export const PINK_OCTOBER = '#FA5DAB';
export const CLEAR_PINK_OCTOBER = '#FFD4E3';

// BUTTONS
export const CA_GRADIENT_BUTTON = 'linear-gradient(79.06deg, #5097E3 -41.6%, #0CAC8C 26.14%, #30DF99 84.04%)';

export const GREEN_LAB = '#0CAC8C';
export const ALMOST_WHITE = '#F3F3F3';

// Doctors
export const GRADIENT_DOC = 'linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)';

// PATIENTS
export const GRADIENT_PAT = 'linear-gradient(90deg, #0CAC72 -97.58%, #0CAC72 20.67%, #CEF719 106.07%, #30DF99 128.35%, #F5EC1D 138.96%)';

export const TEXT_BLACK = '#474A4B';

// Aguascalientes
export const OUTLINE_CARDS = 'linear-gradient(90deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)';