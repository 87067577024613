import Swal from 'sweetalert2';
import validator from 'validator';

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const toastSweet = (icon, message, time) => {
  Toast.fire({
    icon: icon,
    title: message,
    timer: time ? time : 2000
  });
};

export const confirmModal = (icon, title, text, buttonText) => {
  return Swal.fire({
    icon: icon,
    title: title,
    text: text,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: buttonText,
  });
};

export const inputCodeModal = async () => {
  return await Swal.fire({
    title: 'Código de verificación',
    input: 'password',
    inputLabel: 'Ingresa el código que se te envió.',
    inputPlaceholder: 'Código de 6 dígitos',
    confirmButtonColor: '#3085d6',
    inputValidator: (code) => {
      return new Promise((resolve) => {
        if (code.length === 6) {
          resolve();
        } else {
          resolve('Código no válido para verificación.');
        }
      });
    },
  });
};

// export const inputCodeModal = async (email) => {
//     return await Swal.fire({
//         title: 'Ingresa el código que te enviamos',
//         allowEscapeKey: false,
//         // allowOutsideClick: false,
//         // inputLabel: 'Te enviamos un código a tu celular para verificar tu cuenta.',
//         html:
//             '<div id="normal-text"><span>Te enviamos un código a tu celular para verificar tu cuenta.</span></div><br>' +
//             '<input id="digit-1" type="password" maxlength="1" placeholder="-" class="swal2-input">' +
//             '<input id="digit-2" type="password" maxlength="1" placeholder="-" class="swal2-input">' +
//             '<input id="digit-3" type="password" maxlength="1" placeholder="-" class="swal2-input">' +
//             '<input id="digit-4" type="password" maxlength="1" placeholder="-" class="swal2-input">' +
//             '<input id="digit-5" type="password" maxlength="1" placeholder="-" class="swal2-input">' +
//             '<input id="digit-6" type="password" maxlength="1" placeholder="-" class="swal2-input">',
//         // '</div>',
//         focusConfirm: false,
//         preConfirm: () => {
//             const digit1 = document.getElementById('digit-1').value;
//             const digit2 = document.getElementById('digit-2').value;
//             const digit3 = document.getElementById('digit-3').value;
//             const digit4 = document.getElementById('digit-4').value;
//             const digit5 = document.getElementById('digit-5').value;
//             const digit6 = document.getElementById('digit-6').value;

//             // Check digits
//             if (digit1.length > 0 && digit2.length > 0 && digit3.length > 0 && digit4.length > 0 && digit5.length > 0 && digit6.length > 0) {
//                 return `${digit1}${digit2}${digit3}${digit4}${digit5}${digit6}`;
//             } else {
//                 console.log('El correo no es válido');
//             }

//             return false;
//         }
//     });
// }

export const inputEmailModal = async () => {
  return await Swal.fire({
    title: 'Ingresa tu correo',
    allowEscapeKey: false,
    // allowOutsideClick: false,
    input: 'email',
    inputLabel: 'Correo electrónico',
    inputPlaceholder: 'johndoe@test.com',
    inputValidator: (email) => {
      return new Promise((resolve) => {
        if (!validator.isEmail(email) || email.trim().length === 0) {
          resolve('Correo no válido');
        } else {
          resolve();
        }
      });
    },
  });
};

export const inputLoginModal = async () => {
  return await Swal.fire({
    title: 'Ingresa a tu cuenta',
    allowEscapeKey: false,
    // allowOutsideClick: false,
    html:
      `<input id="email" type="email" placeholder="Correo" class="swal2-input" style="width:80%;">` +
      '<input id="password" type="password" placeholder="Contraseña" class="swal2-input" style="width:80%;">' +
      '<br><br>' +
      '<a href="/registro-datos-personales" class="link-login" style="margin-top:10px;">Crear una cuenta</a>',
    focusConfirm: false,
    preConfirm: async () => {
      const email = document.getElementById('email').value;
      const password = document.getElementById('password').value;
      if (password.length > 0) {
        return {
          email: email,
          password: password,
        };
      } else {
        console.log('No hay contraseña');
        await inputLoginModal(email);
      }
    },
  });
};

export const inputRegisterEmailModal = async (email) => {
  return await Swal.fire({
    title: 'Registra tu cuenta',
    allowEscapeKey: false,
    // allowOutsideClick: false,
    html:
      '<input id="name" type="text" placeholder="Nombre" class="swal2-input">' +
      `<input id="email" type="email" placeholder="Correo" class="swal2-input" value="${email}">` +
      '<input id="password" type="password" placeholder="Contraseña" class="swal2-input">' +
      '<input id="password2" type="password" placeholder="Repetir contraseña" class="swal2-input">' +
      '<a href="/datos-personales">Crear una cuenta</a>',
    focusConfirm: false,
    preConfirm: () => {
      const email = document.getElementById('email').value;
      const name = document.getElementById('name').value;
      const password = document.getElementById('password').value;
      const password2 = document.getElementById('password2').value;

      // Check email
      if (validator.isEmail(email) && email.trim().length > 0) {
        // Check name and password
        if (name.length > 5) {
          // Check passwords
          if (
            password.length > 0 &&
            password2.length > 0 &&
            password === password2
          ) {
            return {
              email: email,
              name: name,
              password: password,
            };
          } else {
            console.log('Las contraseñas no coinciden');
          }
        } else {
          console.log('El nombre debe ser mayor a 5 caracteres');
        }
      } else {
        console.log('El correo no es válido');
      }

      return false;
    },
  });
};
