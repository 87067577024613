import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { resetPasswordAuth, startFacebookLogin, startGoogleLogin, startLoginEmailPassword, startLoginWithPhoneNumber, verifiedPhone } from '../../actions/auth';
import { UilEye, UilEyeSlash, UilCheckCircle, UilTimes } from '@iconscout/react-unicons';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { HelmetSEO } from "../../helpers/HelmetSEO";

const googleIcon = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fsocial-media-icons%2Fgoogle-icon.png?alt=media&token=00b8a8f5-6cc9-4a33-bdf3-1ae03ad92928';
const facebookIcon = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fsocial-media-icons%2Ffacebook-icon.png?alt=media&token=d782baf4-bd83-4dc6-be7b-348db43424e0';

const DataForm = styled.form`
  width: 100%;
  max-width: 358px;
  > .form-floating {
    margin-top: 23px;
  }

  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
  }

  > .form-floating > label {
    font-family: Open Sans;
    font-size: 16px;
    color: #A6A6A6;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: 'IBM Plex Sans';
  }

  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
    :focus {
      outline: none;
      -webkit-box-shadow: 0px 0px 5px 5px #00AC9080; 
      box-shadow: 0px 0px 5px 5px #00AC9080;
    }
  }

  > div .form-check label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
  .form-floating {
    position: relative;
    .icon-see-password, .icon-right-email {
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
    .icon-see-password {
      cursor: pointer;
    }
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  position: static;
  width: 100%;
  height: 58px;
  left: 0px;
  top: 0px;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 20px;
  > input {
    background: #f6f6f6;
  }
`;

const LogContainer = styled.div`
  width: 90%;
  margin-left: 5%;
  max-width: 483px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 40px 0;
  margin: 0 auto;
  h1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #3B3B3B;
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    a {
      color: #00AC90;
      text-decoration: underline;
    }
  }
  hr {
    width: 100%;
    border: 1px solid #C1D9D8;
  }
  .phone-row {
    width: 100%;
    max-width: 358px;
    margin-bottom: 24px;
  }
`;

const GreenButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 100%;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: #0a7a71;
  }
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const SignInOptions = styled(Row)`
  flex-direction: row !important;
  width: 100%;
  max-width: 483px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  p {
    margin-bottom: 24px;
  }
`;

const Circle = styled.div`
  background: #FFFFFF;
  border: 1px solid #E9E9E9;
  border-radius: 50%;
  box-shadow: 0px 4px 4px #F6F6F6;
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #00ac90;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #00ac90;
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }

  .form-floating {
    margin-top: 23px;
    width: 90%;
    max-width: 300px;
  }

  .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
  }

  .form-floating > label {
    font-family: Open Sans;
    font-size: 16px;
    color: #A6A6A6;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: 'IBM Plex Sans';
  }

  .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
    :focus {
      outline: none;
      -webkit-box-shadow: 0px 0px 5px 5px #00AC9080; 
      box-shadow: 0px 0px 5px 5px #00AC9080;
    }
  }

`;

export const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [hideCaptcha, setHideCaptcha] = useState(false);
  const { msgError } = useSelector(state => state.ui);
  const [showResetPass, setShowResetPass] = useState(false);
  const [emailReset, setEmailReset] = useState('');

  const dispatch = useDispatch();
  const auth = getAuth();

  useEffect(() => {
    dispatch(verifiedPhone(phone, false, 'Dispatch', ''));
  }, [dispatch, phone]);

  useEffect(() => {
    if (showCaptcha) {
      if ( document.getElementById('recaptcha-container') && (phone?.length > 6) ) {
        console.log("Entra al segundo if");
        try {
          let appVerifier = window.recaptchaVerifier;
          appVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {
              size: 'normal',
              callback: () => {
                if (phone.length > 10) {
                  dispatch(startLoginWithPhoneNumber(phone, appVerifier, () => {
                    setShowCaptcha(false);
                    setHideCaptcha(false);
                  }));
                  setHideCaptcha(true);
                  // setCaptcha(true);
                } else {
                  alert('NO HAY NÚMERO');
                }
              },
              'expired-callback': () => {
                setShowCaptcha(false);
                setHideCaptcha(false);
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
              },
            },
            auth
          );
          appVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
          });
          console.log('OK RECAPTCHA');
        } catch (error) {
          // TODO: handle error when reCAPTCHA doesn't work
          console.log(String(error).split('.')[0]);
        }
      }
    }
  }, [showCaptcha, auth, dispatch, phone]);

  const checkPattern = (emailTest = email) => {
    return /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i.test(emailTest)
  }

  const {
      register,
      handleSubmit,
      watch,
      formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
      console.log(data);
      dispatch(startLoginEmailPassword(data.email, data.password));
  };

  const handleGoogleRegister = () => {
    dispatch(startGoogleLogin(phone));
  };

  const handleFacebookRegister = () => {
    dispatch(startFacebookLogin(phone));
  };

  const handleReadEmail = () => {
    if (emailReset.length > 0 && checkPattern(emailReset)) {
      dispatch(resetPasswordAuth(emailReset));
      setEmailReset('');
    } else {
      alert('Correo no válido');
    }
  };

  return (
    <LogContainer >
      <HelmetSEO
        title='Iniciar Sesión - Pacientes Labopat'
        description='Accede a tu cuenta en Labopat para consultar tus resultados médicos de manera rápida y segura.'
      />
      <DataForm onSubmit={handleSubmit(onSubmit)}>
        <h1>Iniciar sesión</h1>
        <p style={{textAlign:"center",fontSize:"20px" }}>o</p>

        <h2><Link to="/registro">Haz click para registrarte</Link></h2>
        <FloatingLabel controlId='floatingMail' label='Correo Electrónico'>
          <Form.Control
            {...register('email', {
              required: true,
              pattern: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
            })}
            type="email"
            placeholder="pruebas@labopat.mx"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            name="email"
          />
          {checkPattern() && <UilCheckCircle color="#00AC90" className="icon-right-email"/>}
        </FloatingLabel>
        <FloatingLabel controlId='floatingPass' label='Contraseña'>
          <Form.Control
            {...register('password', {
              required: true,
            })}
            type={showPassword ? "text" : "password"}
            placeholder="********"
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {showPassword 
            ? <UilEye color="#00AC90" className="icon-see-password" onClick={()=>setShowPassword(!showPassword)}/> 
            : <UilEyeSlash color="#00AC90" className="icon-see-password" onClick={()=>setShowPassword(!showPassword)}/>
          }
        </FloatingLabel>
        {
          msgError && (
            <Form.Label style={{color: 'red', marginTop: '10px', marginBottom: '0px' }}>
              <b>Error:</b> {msgError}.
            </Form.Label>
          )
        }
        <GreenButton
          variant='primary'
          type='submit'
          disabled={password.length < 1 || email.length < 1}
        >
          Iniciar sesión
        </GreenButton>
      </DataForm>
      <p style={{color: '#00AC90' ,textDecoration: 'underline', cursor: 'pointer', marginBottom: '0px'}} onClick={()=>setShowResetPass(true)}>¿Olvidaste tu contraseña?</p>

      {/* Para resetear contraseña */}
      <StyledModal show={showResetPass} onHide={()=> setShowResetPass(false)}>
        <div onClick={()=> setShowResetPass(false)}>
          <UilTimes color='#fff' style={{ cursor: 'pointer' }} />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>Restablecer contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Te enviaremos un correo para restablecer tu contraseña</label>
          <FloatingLabel controlId='floatingMail' label='Correo Electrónico'>
            <Form.Control
              type="email"
              placeholder="pruebas@labopat.mx"
              value={emailReset}
              onChange={(e) => setEmailReset(e.target.value)}
              name="email"
            />
          </FloatingLabel>
          <GreenButton style={{ width: '80%', maxWidth: '300px' }} onClick={()=>handleReadEmail()}>
            Enviar correo
          </GreenButton>
        </Modal.Body>
      </StyledModal>

      <hr/>
      <SignInOptions>
        <Col xs={{span:4, offset: 2}}>
          <Circle onClick={handleFacebookRegister}>
            <img
              src={facebookIcon}
              style={{ width: '20px', height: '20px' }}
              alt='facebook-icon'
            />
          </Circle>
          <p>Facebook</p>
        </Col>
        <Col xs={{span:4}}>
          <Circle onClick={handleGoogleRegister}>
            <img
              src={googleIcon}
              style={{ width: '20px', height: '20px' }}
              alt='google-icon'
            />
          </Circle>
          <p>Gmail</p>
        </Col>
      </SignInOptions>
      <p style={{color: "#3B3B3B", fontSize: "16px", fontWeight: "400"}}>O usa tu número celular para identificarte</p>
      <Row className='phone-row'>
        <StyledPhoneInput
          international
          type='tel'
          defaultCountry='MX'
          placeholder='Teléfono celular'
          id='phoneNumber'
          value={phone}
          onChange={setPhone}
        />
      </Row>
      <p>Enviaremos un código por mensaje de texto al número que proporcionaste.</p>
      {!showCaptcha && (
        <GreenButton
          variant='primary'
          onClick={()=>{setShowCaptcha(true)}}
          disabled={phone === undefined || phone?.length < 12}
          >
          Enviar código
        </GreenButton>
      )}
      {showCaptcha && (
        <Row style={{display: hideCaptcha?"none" : "block"}}>
          <div
            id='recaptcha-container'
            className='recaptcha-container'
          />
        </Row>
      )}
      <div id="div-for-code">

      </div>
    </LogContainer>
  );
}

